import { FC } from 'react'
import { ContainerSpinner } from './styles'

const SpinnerTable: FC = () => (
  <ContainerSpinner className='fallback-spinner'>
    <div className='loading'>
      <div className='effect-1 effects' />
      <div className='effect-2 effects' />
      <div className='effect-3 effects' />
    </div>
  </ContainerSpinner>
)

export default SpinnerTable