import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { ChevronDown } from 'react-feather'
import DataTable from 'react-data-table-component'
import Select, { SingleValue } from 'react-select'
import SpinnerTable from 'components/Spinner/Table'
import EmptyData from 'components/EmptyData'
import { setItemPath } from 'redux/actions/filters'
import { convertArrayOfObjectsToCSV } from 'utils/layout'
import { Button } from 'reactstrap'
import { TableProps } from './interfaces'
import { ContainerPagination, ContainerSelectedPerPage } from './style'

const Table: FC<TableProps> = ({
  data = [],
  totalPage = 1,
  columns,
  loading,
  onClickedRow,
  onSelectedPage,
  onSelectedLimit,
  numberPage,
  rowsPerPage = 10,
  clickable = true,
  exportCSV = false,
  messageNotFound = 'Nenhum dado encontrado',
  selectableRows = false,
  onSelectedRowsChange,
  selectableRowsSingle = true,
  pagination = true
}) => {
  const optionsRowPerPage = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ]

  const [currentPage, setCurrentPage] = useState(0)
  const [valuePerPage, setValuePerPage] = useState(optionsRowPerPage[0])
  const dispatch = useDispatch()

  const handlePagination = (page: any) => {
    setCurrentPage((page.selected + 1).toString())
    if (onSelectedPage) onSelectedPage(page.selected + 1)
  }

  const handleRowsPerPage = (item: SingleValue<{ label: string, value: number }>) => {
    if (item) {
      setValuePerPage(optionsRowPerPage.find(f => f.value === item.value) ?? optionsRowPerPage[0])
      if (onSelectedLimit) onSelectedLimit(item.value)
    }
  }

  useEffect(() => {
    if (numberPage) {
      if (numberPage > 1) {
        dispatch(setItemPath(['page', numberPage]))
      }
      setCurrentPage(numberPage)
    }

    if (rowsPerPage) {
      if (rowsPerPage > 10) {
        dispatch(setItemPath(['limit', rowsPerPage]))
        setValuePerPage(optionsRowPerPage.find(f => f.value.toString() === rowsPerPage.toString()) ?? optionsRowPerPage[0])
      }
    }
  }, [numberPage, rowsPerPage])

  const downloadCSV = (items: Array<any>) => {
    const link = document.createElement('a')
    let csv = convertArrayOfObjectsToCSV(items)
    if (csv === null) return

    const filename = 'solicitações.csv'

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`
    }

    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', filename)
    link.click()
  }

  const actionsMemo = <Button onClick={() => downloadCSV(data)}>Exportar CSV</Button>

  const CustomPagination = () => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (totalPage === 1) return (<></>)
    return (
      <ContainerPagination>
        <ContainerSelectedPerPage>
          <p>Linhas por página:</p>
          <Select
            value={valuePerPage}
            className='ml-1 select-rows-per-page'
            options={optionsRowPerPage}
            onChange={handleRowsPerPage}
          />
        </ContainerSelectedPerPage>
        <ReactPaginate
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={totalPage || 1}
          marginPagesDisplayed={4}
          pageRangeDisplayed={3}
          activeClassName='active'
          forcePage={numberPage === 1 ? numberPage - 1 : currentPage !== 0 ? currentPage - 1 : 0}
          onPageChange={(page: any) => handlePagination(page)}
          selectedPageRel=""
          pageClassName="page-item"
          nextLinkClassName="page-link"
          nextClassName="page-item next"
          previousClassName="page-item prev"
          previousLinkClassName="page-link"
          pageLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-center pr-1 mt-1"
        />
      </ContainerPagination>
    )
  }

  return (
    <>
      <DataTable
        noHeader={!exportCSV || data.length === 0}
        noDataComponent=''
        pagination={pagination}
        paginationServer
        paginationComponent={CustomPagination}
        paginationPerPage={rowsPerPage}
        className='react-dataTable'
        columns={columns}
        sortIcon={<ChevronDown size={10} />}
        progressPending={loading}
        progressComponent={<SpinnerTable />}
        data={data}
        highlightOnHover
        striped
        actions={actionsMemo}
        pointerOnHover={clickable}
        selectableRowsSingle={selectableRowsSingle}
        onRowClicked={onClickedRow ? (row, e) => onClickedRow(row, e) : undefined}
        selectableRows={selectableRows}
        onSelectedRowsChange={onSelectedRowsChange}
      />
      {data.length === 0 && !loading && <EmptyData message={messageNotFound} width={300} />}
    </>
  )
}

export default Table