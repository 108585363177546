/* eslint-disable import/no-cycle */
// ** Vertical Menu Components
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from 'utils/layout'
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader'

const VerticalNavMenuItems = (props: any) => {
  // ** Components Object
  const Components: any = {
    VerticalNavMenuSectionHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink
  }

  // ** Render Nav Menu Items
  return props.items?.map((item: any) => {
    const TagName = Components[resolveNavItemComponent(item)]
    return <TagName key={item.id || item.header} item={item} {...props} />
  })
}

export default VerticalNavMenuItems
