import { FileText } from 'react-feather'

export default [
  {
    header: 'Kyc'
  },
  {
    id: 'rejectionDocument',
    title: 'Documentoscopia',
    icon: <FileText />,
    navLink: '/kyc-rejection-document'
  }
]