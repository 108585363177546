import { PartnersApi } from 'resources/services/interfaces'
import baseApi from './baseApi'

const createPartnersApi = (LIST_ISSUES_URL: string, CREATE_ISSUES_URL: string): PartnersApi => {
  const listWorkItemsApi = baseApi(LIST_ISSUES_URL, {
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  })
  const createWorkItemsApi = baseApi(CREATE_ISSUES_URL, {
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  })

  return { createAzureTicketApi: createWorkItemsApi, listAzureTicketApi: listWorkItemsApi }
}

export default createPartnersApi