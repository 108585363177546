import { Button } from 'reactstrap'
import styled from 'styled-components'

export const TextButton = styled.span`
  left: 0 !important;
  margin: auto;
`

export const ButtonStyled = styled(Button)`
  width: 100%;
  display: flex;
  align-content: center;
  margin-bottom: 20px;
  flex-direction: row;

  ${props => props.color === 'cancel' && `
    transition: box-shadow .1s !important;
    :hover {
      color: #fff;
      box-shadow: 0 11px 15px rgba(0,0,0,.2);  
    }
    background-color: #5E5873;
    color: #fff;
  `}
`