import { RestApi } from 'resources/services/interfaces'
import { toQueryString } from 'utils/service'
import { InvoicesFilter, PayInvoiceRequest } from './interfaces'

const invoiceService = (_api: RestApi) => ({
  list: async (filter: InvoicesFilter) => _api.get(`backoffice/invoices?${toQueryString(filter)}`),
  pay: async (invoiceId: string, data: PayInvoiceRequest) => _api.post(`backoffice/invoices/${invoiceId}/pay`, data),
  listBankingBillets: async (invoiceId: string) => _api.get(`backoffice/invoices/${invoiceId}/banking-billets`),
})

export default invoiceService