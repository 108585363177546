import { FC, ReactNode } from 'react'
import { Info } from 'react-feather'
import { IconWrapper, InfoTextWrapper, TextContent } from './styles'

interface InfoTextProps {
  children: ReactNode;
  // eslint-disable-next-line react/require-default-props
  backgroundColor?: string;
}

const InfoText: FC<InfoTextProps> = ({ children, backgroundColor = '#eef3f8' }) => (
  <InfoTextWrapper style={{backgroundColor}}>
    <IconWrapper>
      <Info />
    </IconWrapper>
    <TextContent>{children}</TextContent>
  </InfoTextWrapper>
)

export default InfoText