import { createContext, FC, useContext, useState } from 'react'
import { ContextState, User } from './interfaces'

const AuthContext = createContext<Partial<ContextState>>({})

const ContextProvider: FC<any> = ({ children }) => {
  const [user, setUser] = useState<User>()

  const updateUser = (stateToUpdate: User) => {
    setUser((prevState) => ({
      ...prevState,
      ...stateToUpdate
    }))
  }

  const value = {
    data: { user },
    actions: { setUser: updateUser }
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useStoreState = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useStoreState must be used within a ContextProvider')
  }
  return context.data
}

const useStoreActions = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useStoreActions must be used within a ContextProvider')
  }
  return context.actions
}

export default ContextProvider
export { useStoreState, useStoreActions, AuthContext }