// ** React Imports
import React, { FC, useEffect, useState } from 'react'

// ** Third Party Components
import { Badge } from 'reactstrap'
import classnames from 'classnames'
import defaultAvatar from 'assets/images/png/avatar-blank.png'
import { AvatarProps } from './interfaces'

const Avatar: FC<AvatarProps> = ({
  color,
  className,
  img,
  imgClassName,
  initials,
  size,
  badgeUp,
  content,
  icon,
  badgeColor,
  badgeText,
  imgHeight,
  imgWidth,
  status,
  contentStyles,
  ...rest
}) => {
  const [image, setImage] = useState<any>()

  const getInitials = (str: string) => {
    const results: string[] = []
    const wordArray = str.split(' ')
    wordArray.forEach(e => {
      results.push(e[0])
    })
    return results.join('')
  }

  const testImage = (url?: string) => {
    const tester = new Image()
    tester.onload = () => setImage(url)
    tester.onerror = () =>  setImage(defaultAvatar)
    tester.src = url ?? defaultAvatar
  }

  useEffect(() => {
    testImage(img)
  }, [])

  return (
    <div
      className={classnames('avatar', {
        [className]: className,
        [`bg-${color}`]: color,
        [`avatar-${size}`]: size
      })}
      {...rest}
    >
      {!img ? (
        <span
          className={classnames('avatar-content', {
            'position-relative': badgeUp
          })}
          style={contentStyles}
        >
          {initials ? getInitials(content) : content}

          {icon || null}
          
          {badgeUp ? (
            <Badge color={badgeColor || 'primary'} className='badge-sm badge-up' pill>
              {badgeText || '0'}
            </Badge>
          ) : null}
        </span>
      ) : (
        <img
          className={classnames({
            [imgClassName]: imgClassName
          })}
          src={image}
          alt='avatarImg'
          height={imgHeight && !size ? imgHeight : 32}
          width={imgWidth && !size ? imgWidth : 32}
        />
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size
          })}
        />
      ) : null}
    </div>
  )
}

export default Avatar
