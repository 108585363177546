import { Row, Col, Card, CardBody } from 'reactstrap'
import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { FilterGroupProps } from './interfaces'

const FilterGroup: FC<FilterGroupProps> = ({
  title = 'Selecione um Grupo para visualizar suas informações',
  placeholder = 'Selecione um Grupo',
  data,
  setGroupId 
}) => {
  const [companyOptions, setCompanyOptions] = useState<any>([])

  const getSelectList = () => {
    if (data && data.length > 0) {
      const list: any = [] 
      data.forEach(group => {
        list.push({
          value: group.id,
          label: group.name
        })
      })
      setCompanyOptions(list)
    }
  }

  const handleSelectGroup = (e: any) => {
    setGroupId(e.value)
  }
  
  useEffect(() => {
    getSelectList()
  },  [data])

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xl='6'>
            <h4 className='mt-1'>{title}</h4>
          </Col>
          <Col xl='6'>
            <Select 
              className='react-select'
              classNamePrefix='select'
              options={companyOptions}
              name='groups'
              id='groups'
              placeholder={placeholder}
              onChange={(e) => handleSelectGroup(e)}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default FilterGroup