import classnames from 'classnames'
import { FC } from 'react'
import { TimelineItemProps, TimelineProps } from './interfaces'

const Timeline: FC<TimelineProps> = ({data}) => (
  <ul className="timeline">
    {data?.map((item: TimelineItemProps, i: number) => (
      <li key={i} className='timeline-item'>
        <span
          className={classnames('timeline-point', {
            [`timeline-point-${item.color}`]: item.color,
            'timeline-point-indicator': !item.icon})}>
          {item.icon ?? null}
        </span>
        <div className='timeline-event'>
          <div
            className={classnames('d-flex justify-content-between flex-sm-row flex-column', {
              'mb-sm-0 mb-1': item.date
            })}>
            <h6>{item.title}</h6>
            {item.meta &&
              <span
                className={classnames('timeline-event-time', {
                  [item.metaClassName]: item.metaClassName
                })}>
                {item.meta}
              </span>
            }
          </div>
          <div className={classnames({'mb-0': i === data.length - 1 && !item.customContent})}>
            {item.content}
          </div>
          {item.customContent ?? null}
        </div>
      </li>
    ))}
  </ul>
)

export default Timeline