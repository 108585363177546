import { Card } from 'reactstrap'
import styled from 'styled-components'

export const CardStyled = styled(Card)`
  padding: 24px;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.23));
`

export const CardShreddedStyled = styled(CardStyled)`
  :before {
    content: "";
    display: block;
    width: 29px;
    height: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    background-repeat: repeat-y;
    background-position: 0 50%, 0 50%;
    background-size: 25px 20px, 25px 20px;
  }

  :before {
    right: -29px;
    background-image: 
      linear-gradient(-45deg, transparent 70%, white 75%), 
      linear-gradient(-135deg, transparent 70%, white 75%);
  }
`
