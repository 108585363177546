import jwt from 'auth/useJwt'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import jwtDecode from 'jwt-decode'
import qs from 'qs'

const logout = () => {
  localStorage.removeItem('userData')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  // eslint-disable-next-line no-restricted-globals
  location.reload()
}

const tokenHasExpired = () => {
  const token = jwt.getToken()
  if (token) {
    const { exp }: any = jwtDecode(token)
    const expirationTime = (exp * 1000) - 60000
    return Date.now() >= expirationTime
  } 
  return false
}

const getConfig = async () => ({
  headers: {
    'Accept-Language': 'pt-BR',
    Authorization: `Bearer ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`
  },
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  paramsSerializer(params: any) {
    return qs.stringify(params, { arrayFormat: 'indices' })
  }
})

const errorCatcher = (error: any) => {
  if (axios.isCancel(error)) {
    return
  }
  if (error.response && error.response.data) {
    throw error.response.data
  }
}

const baseApi = (baseURL: string, config?: AxiosRequestConfig) => {
  const axiosApi = axios.create({
    baseURL,
    ...config
  })

  axiosApi.interceptors.request.use((config: any) => {
    if (tokenHasExpired()) {
      axios.CancelToken.source()
      logout()
    }
    return config
  })

  axiosApi.interceptors.response.use(undefined, (err: AxiosError) => {
    const error = err.response
    if (error?.status === 401) {
      axios.CancelToken.source()
      logout()
    } else {
      return Promise.reject(err)
    }
  })

  return {
    get: async <T>(url: string, config?: any) => {
      const options = { ...await getConfig(), ...config }
      
      return axiosApi
        .get<T>(url, options)
        .then((resp) => resp.data)
        .catch(errorCatcher)
    },
    post: async <T>(url: string, data?: any, optionalHeader?: any) => {
      const options = await getConfig()
      if (optionalHeader !== null) {
        options.headers ={
          ...options.headers,
          ...optionalHeader
        }
      }

      return axiosApi
        .post<T>(url, data, options)
        .then((resp) => resp.data)
        .catch(errorCatcher)
    },
    put: async <T>(url: string, data: any, optionalHeader?: any) => {
      const options = await getConfig()
      if (optionalHeader !== null) {
        options.headers ={
          ...options.headers,
          ...optionalHeader
        }
      }
      
      return axiosApi
        .put<T>(url, data, options)
        .then((resp) => resp.data)
        .catch(errorCatcher)
    },
    patch: async <T>(url: string, data: any, optionalHeader?: any) => {
      const options = await getConfig()
      if (optionalHeader !== null) {
        options.headers ={
          ...options.headers,
          ...optionalHeader
        }
      }

      return axiosApi
        .patch<T>(url, data, options)
        .then((resp) => resp.data)
        .catch(errorCatcher)
    },
    delete: async <T>(url: string) => {
      const options = await getConfig()

      return axiosApi
        .delete<T>(url, options)
        .then((resp) => resp.data)
        .catch(errorCatcher)
    }
  }
}

export default baseApi