/* eslint-disable import/no-cycle */
import { resolveHorizontalNavMenuItemComponent as resolveNavItemComponent, canViewMenuGroup, canViewMenuItem } from 'utils/layout'
import HorizontalNavMenuLink from './HorizontalNavMenuLink'
import HorizontalNavMenuGroup from './HorizontalNavMenuGroup'

const HorizontalNavMenuItems = (props: any) => {
  const Components = {
    HorizontalNavMenuGroup,
    HorizontalNavMenuLink
  }

  return props.items.map((item: any, index: any) => {
    const TagName = Components[resolveNavItemComponent(item)]
    if (item.children) {
      return canViewMenuGroup(item) && <TagName item={item} index={index} key={item.id} {...props} />
    }
    return canViewMenuItem(item) && <TagName item={item} index={index} key={item.id} {...props} />
  })
}

export default HorizontalNavMenuItems
