import styled from 'styled-components'
import { Label } from 'reactstrap'

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`

export const LabelRight = styled(Label)`
  margin-left: 8px;
  font-size: 1rem;
  font-weight: bold;
`

export const LabelLeft = styled(Label)`
  margin-right: 8px;
  font-size: 1rem;
  font-weight: bold;
`