// **  Initial State
const initialState = {
  userData: {},
  fromRoute: ''
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const authReducer = (state: any = initialState, action: any) => {
  switch (action?.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data.profile,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    case 'SET_FROM_ROUTE':
      return {
        ...state,
        fromRoute: action.path
      }
    default:
      return state
  }
}

export default authReducer
