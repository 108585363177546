import styled from 'styled-components'

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: center;

  > .pagination {
    margin-top: 1.375rem !important;
  }
`

export const ContainerSelectedPerPage = styled.ul`
  margin-top: 1rem;
  margin-right: 1rem;
  display: flex;

  > p {
    margin-top: .5rem;
  }
`