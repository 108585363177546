import styled from 'styled-components'

export const DetailsButton = styled.button`
	display: inline-block;
	padding: 8px;
	border-radius: 4px;
	border: 2px solid #2659d4;
	background-color: transparent;
	color: #2659d4;
	transition: background-color 0.3s;
	text-align: left;
	margin-top: 12px;
	margin-bottom: -4px;

	&:hover {
		background-color: #2659d4;
		color: #fff;
	}

	&:focus {
		background-color: transparent;
		color: #2659d4;
		border-color: #2659d4;
		outline: none;
	}
`

export const TextWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px dotted #000;
	margin-top: 4px;
`

export const Text = styled.p`
	flex: 1;
	text-align: left;
	justify-content: center;
`

export const Value = styled.p`
	flex: 1;
	text-align: right;
	justify-content: center;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 0;
`

export const FormulaTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

export const Formula = styled.p`
	margin: 0;
	font-size: 10px;
	text-align: right;
`
