import noData from 'assets/images/svg/no-data.svg'
import { FC } from 'react'
import { EmptyDataProps } from './interfaces'

const EmptyData: FC<EmptyDataProps> = ({ message = 'Não encontramos dados para serem exibidos.', width = 450, image = noData }) => (
  <div className='centered'>
    <img src={image} width={width} alt="Dados não encontrados" />
    <h5>{message}</h5>
  </div>
)

export default EmptyData