import baseApi from './baseApi'

const createApi = (API_URL: string) =>
  baseApi(API_URL, {
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`
    }
  })

export default createApi