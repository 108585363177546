import {FileText} from 'react-feather'

export default [
  {
    header: 'Pesquisas'
  },
  {
    id: 'surveys',
    title: 'Pesquisas',
    icon: <FileText />,
    navLink: '/pesquisas'
  }
]