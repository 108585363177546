import { RestApi } from 'resources/services/interfaces'
import { MoveAdvancesPayrollRequests, UnpaidPayrollRequest } from './interfaces'

const payrollService = (_api : RestApi) => ({
  getPayrolls: async (companyId: string) => _api.get(`/backoffice/payrolls/group/${companyId}`),
  getPayrollById: async (payrollId: string) => _api.get(`/backoffice/payrolls/${payrollId}`),
  getBankingBilletsOfPayroll: async (payrollId: string) => _api.get(`/backoffice/banking-billets?PayrollId=${payrollId}`),
  listUnpaidPayrolls: async (companyId: string) => _api.get<UnpaidPayrollRequest>(`backoffice/payrolls/group/${companyId}/unpaid-payrolls`),
  moveRequest: async (data: MoveAdvancesPayrollRequests) => _api.post('/backoffice/payrolls/move-payroll-requests', data)
})

export default payrollService