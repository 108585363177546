// ** React Imports
import { FC, useState } from 'react'
import navigation from 'navigation'
import HorizontalNavMenuItems from './HorizontalNavMenuItems'
import { HorizontalMenuProps } from './interfaces'

const HorizontalMenu: FC<HorizontalMenuProps> = ({ currentActiveItem, routerProps }) => {
  const [activeItem, setActiveItem] = useState(null)
  const [groupActive, setGroupActive] = useState([])
  const [openDropdown, setOpenDropdown] = useState([])

  const onMouseEnter = (id: never) => {
    const arr = openDropdown
    arr.push(id)
    setOpenDropdown([...arr])
  }

  const onMouseLeave = (id: never) => {
    const arr = openDropdown
    arr.splice(arr.indexOf(id), 1)
    setOpenDropdown([...arr])
  }

  return (
    <div className='navbar-container main-menu-content'>
      <ul className='nav navbar-nav' id='main-menu-navigation'>
        <HorizontalNavMenuItems
          submenu={false}
          items={navigation}
          activeItem={activeItem}
          groupActive={groupActive}
          routerProps={routerProps}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          openDropdown={openDropdown}
          setActiveItem={setActiveItem}
          setGroupActive={setGroupActive}
          setOpenDropdown={setOpenDropdown}
          currentActiveItem={currentActiveItem}
        />
      </ul>
    </div>
  )
}

export default HorizontalMenu
