import { Survey, SurveyQuestion } from './interfaces'

const initialState: Survey = {
  name: '',
  sumary: '',
  description: '',
  channel: '',
  showToAllCompanies: true,
  companies: Array<string>(),
  showOnLogin: false,
  recurrenceInDays: 0,
  questions: Array<SurveyQuestion>()
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const surveyReducer = (state:Survey = initialState, action: any) => {
  switch (action?.type) {
    case 'HANDLE_SURVEY_SELECTED':
      return {...state, ...action.value }
    case 'RESET_SURVEY':
      return initialState
    default:
      return state
  }
}

export default surveyReducer