import { defaultTheme } from 'providers/Theme/theme'
import { FC, useEffect, useState } from 'react'
import { Archive, Layers, PlusCircle, XCircle } from 'react-feather'
import { Col, FormGroup, Input, Row, Tooltip } from 'reactstrap'
import { ToDoItem, ToDoListProps } from './interfaces'
import { RowBorderedStyled, RowItemStyled } from './styles'

const ToDoList: FC<ToDoListProps> = ({ title, data, setData, emptyMessage = 'Nenhum item criado', readonly }) => {
  const [items, setItems] = useState<Array<ToDoItem>>([])
  const [archivedItems, setArchivedItems] = useState<Array<ToDoItem>>([])
  const [openTooltipAddItem, setOpenTooltipAddItem] = useState<boolean>()
  const [openTooltipLayers, setOpenTooltipLayers] = useState<boolean>()
  const [showArchivedItems, setShowArchivedItems] = useState<boolean>(false)

  const handleRemoveItem = (id: string | number) => {
    setItems(items.filter((item: ToDoItem) => item.id !== id))
  }

  const handleArchiveItem = (id: string | number) => {
    const archivedItem = items.find((item: ToDoItem) => item.id === id)
    if (archivedItem) {
      archivedItem.status = 'inactive'
      setArchivedItems([...archivedItems, archivedItem])
      handleRemoveItem(id)
    }
  }

  const handleChangeLabel = (id: string | number, event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const itemIndex = items.findIndex((item) => item.id === id)
    items[itemIndex].label = event.target.value
    setItems([...items])
  }

  const handleChangeValue = (id: string | number, event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const itemIndex = items.findIndex((item) => item.id === id)
    items[itemIndex].value = event.target.value
    setItems([...items])
  }

  const renderItem = (item: ToDoItem) => (
    <RowItemStyled key={item.id} className='mt-1'>
      <Col xl='8'>
        <Input
          name="description"
          type='text'
          value={item.label}
          onChange={(e) => handleChangeLabel(item.id, e)}
          placeholder='Descrição'
          readOnly={readonly}
          disabled={item.status === 'inactive'}
        />
      </Col>
      <Col xl='2'>
        <Input
          name='percentage'
          type='text'
          value={item.value}
          onChange={(e) => handleChangeValue(item.id, e)}
          placeholder='Valor'
          readOnly={readonly}
          disabled={item.status === 'inactive'}
        />
      </Col> 
      {
        !readonly && item.status !== 'inactive' ?
          <>
            <Col xl='1' className='mt-1'>
              <XCircle color={defaultTheme.palette.error.main} onClick={() => handleRemoveItem(item.id)}/>
            </Col>
            <Col xl='1' className='mt-1'>
              <Archive color={defaultTheme.palette.secondary.main} onClick={() => handleArchiveItem(item.id)}/>
            </Col>
          </>
          : ('')
      }
    </RowItemStyled>
  )

  const handleAddItem = () => {
    if (!readonly)
      setItems([...items, {label: '', value: '', id: items.length + 1, tooltipOpen: false, status: 'active'}])
  }

  useEffect(() => {
    if (data && data.length > 0) {
      setItems(data.filter((item: ToDoItem) => item.status === 'active'))
      setArchivedItems(data.filter((item: ToDoItem) => item.status === 'inactive'))
    }
  }, [])

  useEffect(() => {
    if (setData) {
      setData(() => [...items, ...archivedItems])
    }
  }, [items, archivedItems])

  return (
    <>
      <Row className='mb-1'>
        <Col xl='10'>
          <h4>{title}</h4>
        </Col>
        <Col xl='2' style={{display: 'flex', justifyContent: 'end'}}>
          <Layers id='layers' style={{cursor: 'pointer'}} onClick={() => setShowArchivedItems(!showArchivedItems)}/>
          <Tooltip target='layers' placement='top' isOpen={openTooltipLayers} toggle={() => setOpenTooltipLayers(!openTooltipLayers)}>
            Metas concluídas/arquivadas
          </Tooltip>
          <PlusCircle color={defaultTheme.palette.primary.main} className='ml-1' id='addItem' onClick={handleAddItem} style={{cursor: 'pointer'}}/>
          <Tooltip  target='addItem' placement='top' isOpen={openTooltipAddItem} toggle={() => setOpenTooltipAddItem(!openTooltipAddItem)}>
            Adicionar um novo item
          </Tooltip>
        </Col>
      </Row>
      {items.length === 0 && <RowBorderedStyled className='centered'><span>{emptyMessage}</span></RowBorderedStyled>}
      {items.length > 0 && 
        <FormGroup>
          {items.map((item: ToDoItem) => renderItem(item))}
        </FormGroup>
      }
      {archivedItems.length === 0 && showArchivedItems && 
        <Row className='centered'><span style={{fontStyle: 'italic'}}>Nenhum meta concluída/arquivada</span></Row>
      }
      {archivedItems.length > 0 && showArchivedItems && 
      <>
        <Col xl='12'>
          <h6>Metas concluídas/arquivadas</h6>
        </Col>
        <FormGroup>
          {archivedItems.map((item: ToDoItem) => renderItem(item))}
        </FormGroup>
      </>
      }
    </>
  )
}

export default ToDoList