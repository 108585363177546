import { FC } from 'react'
import { ButtonProps } from './interfaces'
import { ButtonStyled, TextButton } from './styles'

const Button: FC<ButtonProps> = (props) => {
  const { icon: Icon, text, ...rest } = props
  return (
    <ButtonStyled {...rest}>
      <>
        {Icon && <Icon className='float-md-left' />} 
        <TextButton>{text}</TextButton>
      </>
    </ButtonStyled>
  )
}

export default Button