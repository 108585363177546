const getDefaultItems = () => {
  const params = new URLSearchParams(window.location.hash.split('?')[1])
  const array = Array.from(params.entries()).filter(item => item[0] === 'item')
  const items = array.map(item => item[1])
  return items
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const items = (state = getDefaultItems(), action: any) => {
  switch (action?.type) {
    case 'ADD_ITEM':
      return [...state, action.data]
    case 'ADD_ITEMS':
      return [...action.data]
    default:
      return state
  }
}

export default items