import { FC } from 'react'
import { Cell, Pie, PieChart } from 'recharts'

interface PieChartProps {
  score: number
  style: any
}

type ChartData = {
  name: string
  value: number
  color: string
}

const RADIAN = Math.PI / 180
const cx = 150
const cy = 200
const iR = 50
const oR = 100

const PieChartNeedle: FC<PieChartProps>= ({ score, style }) => {
  const data = [
    { name: 'A', value: 50, color: '#ff0000' },
    { name: 'B', value: 150, color: '#fffb00' },
    { name: 'C', value: 300, color: '#00d80b' },
    { name: 'C', value: 500, color: '#078602' },
  ]
  const needle = (value: number, data: Array<ChartData>, cx: number, cy: number, iR: number, oR: number, color: string) => {
    let total = 0
    data.forEach((v) => {
      total += v.value
    })
    const ang = 180.0 * (1 - value / total)
    const length = (iR + 2 * oR) / 3
    const sin = Math.sin(-RADIAN * ang)
    const cos = Math.cos(-RADIAN * ang)
    const r = 5
    const x0 = cx + 5
    const y0 = cy + 5
    const xba = x0 + r * sin
    const yba = y0 - r * cos
    const xbb = x0 - r * sin
    const ybb = y0 + r * cos
    const xp = x0 + length * cos
    const yp = y0 + length * sin

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
    ]
  }

  return (
    <PieChart width={280} height={230} style={style}>
      <Pie
        accentHeight={20}
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        strokeWidth={4}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      {needle(score, data, cx, cy, iR, oR, '#000')}
    </PieChart>
  )
}

export default PieChartNeedle