import baseApi from './baseApi'

const createAuthApi = (baseUrl: string) =>
  baseApi(`${baseUrl}`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
  })

export default createAuthApi