import { Card, CardText } from 'reactstrap'
import styled from 'styled-components'

export const CardStyled = styled(Card)`
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
  min-height: 340px;
`

export const AvailableLimitStyled = styled.label`
  color: #23b936;
  font-size: 1.5rem;
  font-weight: bold;
`

export const TitleAccumulateStyled = styled(CardText)`
  color: #2659d4;
  font-size: 0.875rem;
  margin-bottom: -4px;
`

export const AccumulateValueStyled = styled.label`
  color: #2659d4;
  font-size: 1.5rem;
  font-weight: bold;
`

export const UnavailableValueStyled = styled.label`
  color: #6E6B7B;
  font-size: 1.5rem;
  font-weight: bold;
`

export const TitleUnavailableStyled = styled(CardText)`
  color: #6E6B7B;
  font-size: 0.875rem;
  margin-top: 8px;
  margin-bottom: -4px;
`