import { Activity, Clock, Table, Users } from 'react-feather'

export default [
  {
    header: 'Planejamento Financeiro'
  },
  {
    id: 'planejadores',
    title: 'Planejadores',
    icon: <Users />,
    navLink: '/planejadores'
  },
  {
    id: 'agendamentos',
    title: 'Agendamentos',
    icon: <Clock />,
    navLink: '/agendamentos'
  },
  {
    id: 'planejamentos',
    title: 'Planejamentos',
    icon: <Table />,
    navLink: '/planejamentos'
  },
  {
    id: 'planejamentosAI',
    title: 'Planejamentos AI',
    icon: <Activity />,
    navLink: '/planejamentos/ai'
  },
]