import { CheckCircle, Layers } from 'react-feather'

export default [
  {
    id: 'advanceRequests',
    title: 'Solicitações',
    icon: <Layers />,
    navLink: '/solicitacoes'
  },
  {
    id: 'internal-approval',
    title: 'Aprovação Interna',
    icon: <CheckCircle />,
    navLink: '/aprovacoes-internas'
  }
]