import { Badge } from 'reactstrap'
import styled from 'styled-components'

export const BadgeNPS = styled(Badge)`
  background-color: ${props => props.color} !important;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BadgeRightStyled = styled(Badge)`
  display: flex;
  margin: 0 auto;
  margin-right: 0;
`