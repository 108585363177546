import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { Col, Label, Row } from 'reactstrap'
import { InputCurrencyProps } from './interfaces'
import { InputCurrencyStyled } from './styles'

const InputCurrency: FC<InputCurrencyProps> = ({
  name,
  control,
  label,
  register,
  hasForm = false,
  ...rest
}) => {
  if (!hasForm) {
    return <InputCurrencyStyled name={name} {...rest} />
  }

  const registerInput = register(name)

  return <Controller
    name={name ?? ''}
    control={control}
    render={({
      field: { value, onChange },
      fieldState: { error }
    }) => (
      <Row>
        <Col xl='12'>
          {label && <Label htmlFor={name}>{label}</Label>}
          <InputCurrencyStyled
            value={value}
            onChange={onChange}
            ref={registerInput.ref}
            {...rest}
          />
          {error && <span role='alert' className='text-danger'>{error.message}</span>}
        </Col>
      </Row>
    )}
  />
}

export default InputCurrency