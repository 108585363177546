import Button from 'components/Button'
import { createElement, FC } from 'react'
import { Col } from 'reactstrap'
import { FormProps } from './interfaces'
import { FooterActionStyled } from './styles'

const Form: FC<FormProps> = ({
  defaultValues,
  buttonLabel = 'Confirmar',
  children,
  onSubmit,
  handleSubmit,
  register,
  fullButtonConfirm,
  loading,
  showButtonSubmit = true,
  colorSubmitButton = 'success',
  iconButtonSubmit,
  ...rest
}) => (
  <form onSubmit={handleSubmit(onSubmit)} {...rest}>
    <div>
      {Array.isArray(children) ?
        children.map((child) => child.props?.name ?
          createElement(child.type, {
            ...{
              key: child.props.name,
              register,
              ...child.props
            }
          }) : child)
        : children
      }
    </div>
    {showButtonSubmit && <FooterActionStyled>
      <Col xl={fullButtonConfirm ? '12' : '4'}>
        <Button icon={iconButtonSubmit} color={colorSubmitButton} text={buttonLabel} disabled={loading} />
      </Col>
    </FooterActionStyled>}
  </form>
)

export default Form