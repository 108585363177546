/* eslint-disable import/no-cycle */
import { FC, useEffect, useState } from 'react'
import { Divider } from 'components'
import { Info } from 'react-feather'
import { CardBody, CardText, Col, Progress, Row, Tooltip } from 'reactstrap'
import { formatMoney, getBadgeProductStatus } from 'utils/layout'
import { CardAvailableLimitProps } from './interfaces'
import {
  AccumulateValueStyled,
  AvailableLimitStyled,
  CardStyled,
  TitleAccumulateStyled,
  TitleUnavailableStyled,
  UnavailableValueStyled
} from './styles'

const CardAvailableLimit: FC<CardAvailableLimitProps> = ({
  advancesReleasedValue,
  availableLimitValue,
  maximumAdvanceValue,
  title,
  titleLeftBottom,
  titleRightBottom,
  textTooltip,
  status
}) => {
  const [availableLimitPercent, setAvailableLimitPercent] = useState<number>()
  const [accumulatePercent, setAccumulatePercent] = useState<number>()
  const [unavailablePercent, setUnavailablePercent] = useState<number>()
  const [openTooltip, setOpenTooltip] = useState<boolean>()
  const [isProductActive, setIsProductActive] = useState<boolean>(false)

  const calculateProgressBar = () => {
    const totalValue = advancesReleasedValue + availableLimitValue + maximumAdvanceValue
    setAvailableLimitPercent((100 * availableLimitValue) / totalValue)
    setAccumulatePercent((100 * advancesReleasedValue) / totalValue)
    setUnavailablePercent((100 * maximumAdvanceValue) / totalValue)
  }

  useEffect(() => {
    calculateProgressBar()
    setIsProductActive(status === 'ativo')
  }, [])

  return (
    <CardStyled>
      <CardBody>
        <CardText>{title}</CardText>
        <p>{getBadgeProductStatus(status, false)}</p>
        <AvailableLimitStyled style={{opacity: !isProductActive ? 0.5 : 1}}>{formatMoney(availableLimitValue)}</AvailableLimitStyled>
        <Progress style={{opacity: !isProductActive ? 0.5 : 1}} className='mt-1' multi>
          <Progress bar color="success" value={availableLimitPercent} />
          <Progress bar value={accumulatePercent} />
          <Progress bar style={{backgroundColor: '#BABFC7'}} value={unavailablePercent} />
        </Progress>
        <Divider />
        <Row style={{opacity: !isProductActive ? 0.5 : 1}}>
          <Col xl="12">
            <TitleAccumulateStyled>
              {titleLeftBottom}
              {textTooltip &&
                <>
                  <Info style={{height: 17}} id="info" />
                  <Tooltip target='info' placement='right' isOpen={openTooltip} toggle={() => setOpenTooltip(!openTooltip)}>
                    {textTooltip}
                  </Tooltip>
                </>
              }
            </TitleAccumulateStyled>
            <AccumulateValueStyled>{formatMoney(advancesReleasedValue)}</AccumulateValueStyled>
          </Col>
          <Col xl="12">
            <TitleUnavailableStyled>{titleRightBottom}</TitleUnavailableStyled>
            <UnavailableValueStyled>{formatMoney(maximumAdvanceValue)}</UnavailableValueStyled>
          </Col>
        </Row>
      </CardBody>
    </CardStyled>
  )
}

export default CardAvailableLimit