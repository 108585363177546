/* eslint-disable import/no-cycle */
import { FC } from 'react'
import Spinner from 'components/Spinner'
import { Button } from 'components'
import { ModalBody, ModalFooter, ModalHeader, Modal as ModalReact } from 'reactstrap'
import { ModalProps } from './interfaces'
import { ContainerActions } from './styles'

const Modal: FC<ModalProps> = ({
  open,
  setOpen,
  title,
  children,
  isConfirm = false,
  closeText = 'Cancelar',
  confirmText = 'Confirmar',
  handleConfirm = () => {},
  size,
  loading,
  colorClose,
  colorSuccess,
  footer = null,
  fullscreen = false
}) => (
  <ModalReact
    isOpen={open}
    toggle={() => setOpen(!open)} 
    className='modal-dialog-centered'
    size={size}
    fullscreen={fullscreen}
  >
    <ModalHeader
      toggle={() => setOpen(!open)}
      close={<button className="close" onClick={() => setOpen(!open)}>×</button>}
    >
      {title}
    </ModalHeader>
    <ModalBody>
      {children}
    </ModalBody>
    {
      footer || (
        <ModalFooter className='centered'>
          {loading && <Spinner />}
          {!loading && 
        <ContainerActions>
          <Button color={colorClose ?? 'danger'} onClick={() => setOpen(!open)} text={closeText} />
          {isConfirm && <Button className='ml-1' color={colorSuccess ?? 'success'} onClick={() => handleConfirm()} text={confirmText} />}
        </ContainerActions>
          }
        </ModalFooter>)
    }
  </ModalReact>
)

export default Modal