// ** Redux Imports
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import companies from './companies'
import filters from './filters'
import survey from './survey'

const history = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(history),
  filters,
  auth,
  navbar,
  layout,
  companies,
  survey
})

export default rootReducer
