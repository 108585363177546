import { FeatureFlagRequest } from 'containers/Features/PanicButtonItem/interfaces'
import { RestApi } from '../interfaces'
import { FeatureFlagsResponse } from './interfaces'

const featureService = (_api: RestApi) => ({
  changeStatusFeatureFlag: async (request: FeatureFlagRequest) => {
    const { key, activate, typeUpdate, userEmail } = request
    await _api.patch(`/backoffice/feature-flags/${key}`, { enable: activate, userEmail, typeUpdate })
  },
  getAllFeatureFlags: async () => _api.get<FeatureFlagsResponse[]>('/backoffice/feature-flags/')
})

export default featureService