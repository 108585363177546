import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { Col, Input, Label, Row } from 'reactstrap'
import { InputReactProps } from './interfaces'

const InputReact: FC<InputReactProps> = ({
  control,
  name = '',
  label,
  register,
  ...rest
}) => 
  (
    <Controller
      control={control}
      name={name}
      render={({
        field:{value, onChange},
        fieldState: { error }
      }) => (
        <Row>
          <Col xl='12'>
            {label && <Label htmlFor={name}>{label}</Label>}
            <Input
              value={value}
              onChange={onChange}
              {...rest}
            />
            {error && <span role='alert' className='text-danger'>{error.message}</span>}
          </Col>
        </Row>
      )}
    />
  )


export default InputReact