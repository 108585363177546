/* eslint-disable import/no-cycle */
import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'

import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { isNavGroupActive } from 'utils/layout'
import HorizontalNavMenuItems from './HorizontalNavMenuItems'
import { HorizontalNavMenuGroupProps } from './interfaces'

const HorizontalNavMenuGroup: FC<HorizontalNavMenuGroupProps> = ({
  item,
  submenu,
  groupActive,
  onMouseEnter,
  onMouseLeave,
  openDropdown,
  setGroupActive,
  activeItem,
  setActiveItem,
  routerProps,
  setOpenDropdown,
  currentActiveItem
}) => {
  const currentURL = useLocation().pathname
  return (
    <Dropdown
      tag='li'
      className={classnames({
        'nav-item': submenu === false,
        'dropdown-submenu': submenu === true,
        'sidebar-group-active active':
          isNavGroupActive(item.children, currentURL, routerProps) || groupActive.includes(item.id)
      })}
      isOpen={openDropdown.includes(item.id)}
      toggle={() => onMouseEnter(item.id)}
      onMouseEnter={() => onMouseEnter(item.id)}
      onMouseLeave={() => onMouseLeave(item.id)}
    >
      <DropdownToggle
        to='/'
        tag={Link}
        className={classnames('dropdown-toggle d-flex align-items-center', {
          'dropdown-item': submenu === true,
          'nav-link': submenu === false
        })}
        onClick={e => e.preventDefault()}
      >
        {item.icon}
        <span>
          <FormattedMessage id={item.title} />
        </span>
      </DropdownToggle>
      <DropdownMenu tag='ul'>
        <HorizontalNavMenuItems
          submenu
          parentItem={item}
          items={item.children}
          activeItem={activeItem}
          groupActive={groupActive}
          routerProps={routerProps}
          openDropdown={openDropdown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          setActiveItem={setActiveItem}
          setGroupActive={setGroupActive}
          isChild
          setOpenDropdown={setOpenDropdown}
          currentActiveItem={currentActiveItem}
        />
      </DropdownMenu>
    </Dropdown>
  )
}

export default HorizontalNavMenuGroup
