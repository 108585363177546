import { DefaultTheme } from 'styled-components'

export const defaultTheme: DefaultTheme = {
  borderRadius: '4px',
  palette: {
    common: {
      black: '#222831',
      white: '#ffffff'
    },
    primary: {
      main: '#2659d4',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#82868b',
      contrastText: '#ffffff'
    },
    success: {
      main: '#23b936',
      contrastText: '#fff'
    },
    error: {
      main: '#df4e4f',
      contrastText: '#fff'
    },
    warning: {
      main: '#ffb600',
      contrastText: '#222831'
    },
    info: {
      main: '#00CFE8',
      contrastText: '#fff'
    },
    disabled: {
      main: '#c6c6c6',
      contrastText: '#ffffff'
    },
  }
}