import useJwt from 'auth/useJwt'
import { ResponseUserData } from './interfaces'

const config = useJwt.jwtConfig

export const handleLogin = (data: ResponseUserData) => (dispatch: any) => {
  dispatch({
    type: 'LOGIN',
    data,
    config,
    [config.storageTokenKeyName]: data.signIn.tokens.accessToken,
    [config.storageRefreshTokenKeyName]: data.signIn.tokens.refreshToken
  })

  localStorage.setItem('userData', JSON.stringify(data.profile))
  localStorage.setItem(config.storageTokenKeyName, JSON.stringify(data.signIn.tokens.accessToken))
  localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(data.signIn.tokens.refreshToken))
}

export const handleFromRoute = (path: string) => (dispatch: any) => {
  dispatch({type: 'SET_FROM_ROUTE', path})
}

export const handleLogout = () => (dispatch: any) => {
  dispatch({ type: 'LOGOUT', [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })

  localStorage.removeItem('userData')
  localStorage.removeItem(config.storageTokenKeyName)
  localStorage.removeItem(config.storageRefreshTokenKeyName)
}
