import { FC, useEffect, useState } from 'react'
import { NavItem, NavLink } from 'reactstrap'
import { Menu } from 'react-feather'
import { useHistory, useLocation } from 'react-router-dom'
import Select from 'react-select'
import { handleCompanyList, handleCompanySelected } from 'redux/actions/companies'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from 'providers/Api'
import { toast } from 'react-toastify'
import UserDropdown from './UserDropdown'
import { NavbarProps } from './interfaces'
import { DividerMenu } from './styles'

const ThemeNavbar: FC<NavbarProps> = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [firstTitle, setFirstTitle] = useState('')
  const [secondTitle, setSecondTitle] = useState('')
  const userDataLocal = localStorage.getItem('userData')
  const userData = userDataLocal ? JSON.parse(userDataLocal) : {}
  const store = useSelector<any, any>(state => state)
  const { companyService } = useApi()
  const [companiesOptions, setCompanyOptions] = useState(store?.companies?.companyList)
  const [selectedCompany, setSelectedCompany] = useState([{ value: 'all', label: 'Todas as empresas' }])

  const history = useHistory()

  const renderTitlePage = () => {
    const paths = history.location.pathname.split('/')
    switch (paths[1]) {
      case 'solicitacoes':
        setFirstTitle('Solicitações')
        setSecondTitle('Lista')
        break
      case 'solicitacao':
        setFirstTitle('Solicitação')
        setSecondTitle('Detalhe')
        break
      case 'aprovacoes-internas':
        setFirstTitle('Solicitações')
        setSecondTitle('Aprovação Interna')
        break
      case 'colaboradores':
        setFirstTitle('Colaboradores')
        setSecondTitle('Lista')
        break
      case 'colaborador':
        setFirstTitle('Colaborador')
        setSecondTitle('Detalhe')
        break
      case 'agendamentos':
        setFirstTitle('Planejamento Financeiro')
        setSecondTitle('Agendamentos')
        break
      case 'agendamento':
        setFirstTitle('Planejamento Financeiro')
        setSecondTitle('Primeira reunião')
        break
      case 'planejadores':
        setFirstTitle('Planejamento Financeiro')
        setSecondTitle('Planejadores')
        break
      case 'planejador':
        setFirstTitle('Planejamento Financeiro')
        setSecondTitle('Planejador')
        if (paths[2] === 'concluir-cadastro') {
          setSecondTitle('')
        }
        break
      case 'planejamentos':
        setFirstTitle('Planejamento Financeiro')
        setSecondTitle('Planejamentos')
        break
      case 'planejamento':
        setFirstTitle('Planejamento Financeiro')
        setSecondTitle('Detalhe')
        if (paths[2] === 'novo') {
          setSecondTitle('Novo')
        }
        break
      case 'pesquisas':
        setFirstTitle('Pesquisa Dinâmica')
        setSecondTitle('Lista')
        break
      case 'nova-pesquisa':
        setFirstTitle('Pesquisa Dinâmica')
        setSecondTitle('Nova Pesquisa')
        break
      case 'pesquisa':
        setFirstTitle('Pesquisa Dinâmica')
        setSecondTitle('Detalhe')
        break
      case 'funcionalidades':
        setFirstTitle('Funcionalidades')
        setSecondTitle('Habilita/Desabilita as funcionalidades e produtos da Pilla')
        break
      case 'kyc-rejection-document':
        setFirstTitle('Kyc')
        setSecondTitle('Lista clientes com documento rejeitado')
        break
      case 'empresas':
        setFirstTitle('Empresas')
        if (paths[2] === 'folhas')
          setSecondTitle('Folhas')
        break
      default:
        setFirstTitle('')
        setSecondTitle('')
        break
    }
  }

  const changeCompany = (e: any) => {
    dispatch(handleCompanySelected(e.value))
    setSelectedCompany([{ value: e.value, label: e.label }])
  }

  const formatGroupLabel = (data: any) => (
    <div className='d-flex justify-content-between align-center'>
      <strong>
        <span>{data.label}</span>
      </strong>
    </div>
  )

  const setCompanies = () => {
    const companyList = [{ label: '', options: [{ value: 'all', label: 'Todas as Empresas' }] }]

    if (companyService) {
      setLoading(true)
      companyService.getCompanies()
        .then((res: any) => {
          const matrix: any = []
          const groups: any = []
          const bussinesesUnit: any = []

          if (res) {
            res.data.forEach((item: any) => {
              if (item.type.toLowerCase() === 'matrix') {
                matrix.push({ value: item.companyGroupId, label: item.name })
              }

              if (item.type.toLowerCase() === 'group') {
                groups.push({ value: item.companyID ?? item.companyId, label: item.name })
              }

              if (item.type.toLowerCase() === 'bu') {
                bussinesesUnit.push({ value: item.companyGroupId, label: item.name })
              }
            })
          }

          companyList.push({ label: 'Matrizes', options: matrix })
          companyList.push({ label: 'Grupos', options: groups })
          companyList.push({ label: 'Filiais', options: bussinesesUnit })
        }).catch((err) => {
          console.error(err)
          toast.error('Erro ao buscar empresas')
        }).finally(() => setLoading(false))
    }
    dispatch(handleCompanyList(companyList))
    dispatch(handleCompanySelected(userData?.companyData?.companyGroupId))
    setCompanyOptions(companyList)
    setSelectedCompany(companyList[0].options)
  }

  useEffect(() => {
    if (!store.companies.companylist || store.companies.companyList.length === 0 || store.companies.companySelectedId === '') {
      setCompanies()
    }
    renderTitlePage()
  }, [history.location.pathname])

  useEffect(() => {
    renderTitlePage()
  }, [location])

  return (
    <>
      <ul className='navbar-nav d-xl-none d-flex align-items-center'>
        <NavItem className='mobile-menu mr-auto'>
          <NavLink className='nav-menu-main menu-toggle hidden-xs is-active'>
            <Menu className='ficon' />
          </NavLink>
        </NavItem>
      </ul>
      <div className='bookmark-wrapper d-flex align-items-center'>
        <p className="mt-10 user-name font-weight-bold" style={{ marginTop: 15, marginLeft: 10, fontSize: 20 }}>
          {firstTitle}
        </p>
        {secondTitle &&
          <>
            <DividerMenu />
            <p style={{ marginLeft: 10, paddingTop: 16 }}>
              {secondTitle}
            </p>
          </>
        }
      </div>
      <ul className='nav navbar-nav align-items-center ml-auto'>
        {history.location.pathname.split('/')[1] !== 'empresas' && (
          <div style={{ width: '250px', marginLeft: 10, marginRight: 5 }}>
            <Select
              value={selectedCompany}
              isLoading={loading}
              className='react-select'
              classNamePrefix='select'
              formatGroupLabel={formatGroupLabel}
              options={companiesOptions}
              isClearable={false}
              onChange={changeCompany}
            />
          </div>
        )}
        <UserDropdown />
      </ul>
    </>
  )
}

export default ThemeNavbar
