
import { PartnersApi, } from '../interfaces'
import {
  CreateissueInput,
} from './interfaces'

const userData: any = JSON.parse(localStorage.getItem('userData') || '{}')
const pipeDreamService = (_api: PartnersApi) => ({

  createIssue: async (input: CreateissueInput) => {
    const payload = {
      title: input.title,
      document: input.document,
      personId: input.personId,
      description: input.description,
      companyDocument: input.companyDocument,
      level: input.level,
      companyName: input.companyName,
      responsible: userData.name,
      responsibleEmail: userData.email
    }
    if (payload.responsibleEmail === undefined) {
      return
    }
    return _api.createAzureTicketApi.post('/', {
      title: input.title,
      document: input.document,
      personId: input.personId,
      description: input.description,
      companyDocument: input.companyDocument,
      level: input.level,
      companyName: input.companyName,
      responsible: userData.name,
      responsibleEmail: userData.email
    })
  },

  getIssuesByDocument: async (document: String) => {
    const data = await _api.listAzureTicketApi.get(`/?document=${document}`)
    return data
  }

})

export default pipeDreamService