// ** React Imports
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

// ** Third Party Components
import themeConfig from 'utils/configs/themeConfig'

// ** Config
const VerticalMenuHeader = (props: any) => {
  // ** Props
  const { menuCollapsed, setGroupOpen, menuHover } = props

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  return (
    <div className='navbar-header'>
      <ul className='nav navbar-nav flex-row'>
        <li className='nav-item mr-auto'>
          <NavLink to='/' className='navbar-brand'>
            <span className='brand-logo'>
              <img src={themeConfig.app.appLogoImage} alt='logo' />
            </span>
            <h2 className='brand-text mb-0'>{themeConfig.app.appName}</h2>
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
