import { push } from 'connected-react-router'

const getItemPath = (items: any) => {
  const params = new URLSearchParams()
  items.forEach((item: any) => {
    if (params.has(item[0])) {
      params.delete(item[0])
    }
    params.append(item[0], item[1])
  })
  return params.toString()
}

const setItemPath = (value: any) => (dispatch: (arg0: { type: string; data: any }) => any) => {
  dispatch({ type: 'ADD_ITEM', data: value})
}

const FilterMiddleware = (store: any) => (next: any) => (action: any) => {
  if(action.type === 'ADD_ITEM') {
    const state = store.getState()
    const itemsPath = getItemPath([...state.filters, action.data])
    store.dispatch(push(`${state.router.location.pathname}?${itemsPath}`))
  }
  next(action)
}

export {getItemPath, FilterMiddleware, setItemPath}