import styled from 'styled-components'

export const InfoTextWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: #eef3f8;
	padding: 10px;
	border-radius: 4px;
`

export const IconWrapper = styled.span`
	margin-right: 8px;
`

export const TextContent = styled.p`
	margin: 0;
	color: '#664D03';
`
