import { RestApi } from 'resources/services/interfaces'
import { SurveyRequest } from './interfaces'

const surveyService = (_api: RestApi) => ({
  listSurveys: async () => _api.get('/backoffice/surveys'),
  getSurvey: async (surveyId: string) => _api.get(`/backoffice/surveys/${surveyId}`),
  getAnswers: async (surveyId: string) => _api.get(`/backoffice/surveys/${surveyId}/answers`),
  addSurvey: async (data: SurveyRequest) => _api.post('/backoffice/surveys', data),
  editSurvey: async (data: SurveyRequest, surveyId: string) => _api.patch(`/backoffice/surveys/${surveyId}`, data),
  activateSurvey: async (surveyId: string) => _api.patch(`/backoffice/surveys/${surveyId}`, {status: 'ativo'}),
  deactivateSurvey: async (surveyId: string) => _api.patch(`/backoffice/surveys/${surveyId}`, {status: 'inativo'}),
  downloadAnswers: async (surveyId: string) => _api.get(`/backoffice/surveys/${surveyId}/answers/export`, {responseType: 'blob'})
})

export default surveyService