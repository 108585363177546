import CurrencyInput from 'react-currency-input-field'
import styled from 'styled-components'

export const InputCurrencyStyled = styled(CurrencyInput)`
  display: block;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.438rem 1rem;
  background-color: ${props => props.disabled ? '#efefef' : '#fff'};
  width: 100%;
`