/* eslint-disable import/no-cycle */
import { FC, useEffect, useState } from 'react'
import { Divider, InfoText, Modal } from 'components'
import { useApi } from 'providers/Api'
import { toast } from 'react-toastify'
import {
  Button,
  CardBody,
  CardText,
  Col,
  ModalBody,
  ModalFooter,
  Progress,
  Row,
} from 'reactstrap'
import { formatMoney, formatUtcDate, getBadgeProductStatus } from 'utils/layout'
import {
  AccumulateValueStyled,
  AvailableLimitStyled,
  CardStyled,
  TitleAccumulateStyled,
} from '../styles'
import {
  CardAvailableConsignmentLimitProps,
  CreditAnalysis,
} from './interfaces'
import {
  DetailsButton,
  Formula,
  FormulaTextWrapper,
  Text,
  TextWrapper,
  Value,
} from './styles'

const CardAvailableConsignmentLimit: FC<CardAvailableConsignmentLimitProps> = ({
  availableLimitValue,
  amountConsignmentUnpaid,
  personId,
  customerId,
  paycheckMaximumAllowed,
  paycheckTotalDiscount,
  status,
  hasConsignmentRestrictedByPartner
}) => {
  const [availableLimitPercent, setAvailableLimitPercent] = useState<number>()
  const [accumulatePercent, setAccumulatePercent] = useState<number>()
  const [openDetails, setOpenDetails] = useState<boolean>(false)
  const [creditAnalysisData, setCreditAnalysisData] = useState<CreditAnalysis>()
  const [hasCreditAnalysis, setHasCreditAnalysis] = useState<boolean>(false)
  const [isProductActive, setIsProductActive] = useState<boolean>(false)
  const { orderService } = useApi()

  const calculateProgressBar = () => {
    const totalValue = amountConsignmentUnpaid + availableLimitValue
    setAvailableLimitPercent((100 * availableLimitValue) / totalValue)
    setAccumulatePercent((100 * amountConsignmentUnpaid) / totalValue)
  }

  const calculateMaximumPaycheckAmount = (
    maximumAllowedAmount: number,
    advancesTotalDiscount: number
  ) => maximumAllowedAmount - advancesTotalDiscount

  const calculateConsignmentLimit = (
    maximumAllowedAmount: number,
    advancesTotalDiscount: number,
    termInMonths: number
  ) =>
    calculateMaximumPaycheckAmount(
      maximumAllowedAmount,
      advancesTotalDiscount
    ) * termInMonths

  const getAnalysisTypeLabel = (analysisType: string) => {
    switch (analysisType.toLowerCase()) {
      case 'preapproved':
        return 'Pré-aprovada'
      case 'fullanalysis':
        return 'Completa'
      default:
        return analysisType
    }
  }

  const getAnalysisStatusLabel = (analysisStatus: string) => {
    switch (analysisStatus.toLowerCase()) {
      case 'approved':
        return 'Aprovada'
      case 'denied':
        return 'Negada'
      case 'applyrestrictions':
        return 'Aprovada com restrições'
      default:
        return analysisStatus
    }
  }

  const getConsignmentLimit = () => {
    const netLimit = calculateConsignmentLimit(
      paycheckMaximumAllowed,
      paycheckTotalDiscount,
      creditAnalysisData!.termInMonths
    )
    const calculatedLimit =
			creditAnalysisData!.limit > netLimit
			  ? netLimit
			  : creditAnalysisData!.limit

    return calculatedLimit - amountConsignmentUnpaid >= 250
      ? calculatedLimit
      : 0
  }

  const getConsignmentLimitFormulaLabel = () => {
    const netLimit = calculateConsignmentLimit(
      paycheckMaximumAllowed,
      paycheckTotalDiscount,
      creditAnalysisData!.termInMonths
    )

    const calculatedLimit =
			creditAnalysisData!.limit > netLimit
			  ? netLimit
			  : creditAnalysisData!.limit

    if (calculatedLimit - amountConsignmentUnpaid < 250) return '< R$ 250,00'

    return creditAnalysisData!.limit > netLimit
      ? 'ACVM > D = D'
      : 'D > ACVM = ACVM'
  }

  const getCreditAnalysisData = (personId: string) => {
    if (orderService) {
      orderService
        .getHighestAmountCreditAnalysis(personId)
        .then((res: any) => {
          if (res) {
            setCreditAnalysisData(res)
            setHasCreditAnalysis(true)
          } else {
            setCreditAnalysisData(undefined)
            setHasCreditAnalysis(false)
          }
        })
        .catch((err) => {
          console.error(err)
          toast.error('Erro ao obter análise de crédito.')
        })
    }
  }

  const requestCreditAnalysis = () => {
    if (orderService && customerId) {
      orderService.startCreditAnalysis(customerId)
      setOpenDetails(false)
      toast.info('Análise de crédito solicitada com sucesso. Aguarde alguns minutos e atualize a página.')
    }
  }

  useEffect(() => {
    if (personId) getCreditAnalysisData(personId)

    setIsProductActive(status === 'ativo')
    calculateProgressBar()
  }, [])

  return (
    <>
      <CardStyled>
        <CardBody>
          <CardText>Disponível para crédito consignado</CardText>
          <p>{getBadgeProductStatus(status,hasConsignmentRestrictedByPartner)}</p>
          <AvailableLimitStyled style={{opacity: !isProductActive ? 0.5 : 1}}>
            {formatMoney(availableLimitValue)}
          </AvailableLimitStyled>
          <Progress style={{opacity: !isProductActive ? 0.5 : 1}} className="mt-1" multi>
            <Progress bar color="success" value={availableLimitPercent} />
            <Progress bar value={accumulatePercent} />
          </Progress>
          <Divider />
          <Row style={{opacity: !isProductActive ? 0.5 : 1}}>
            <Col xl="12">
              <TitleAccumulateStyled>
								Consignados realizados
              </TitleAccumulateStyled>
              <AccumulateValueStyled>
                {formatMoney(amountConsignmentUnpaid)}
              </AccumulateValueStyled>
            </Col>
            <Col xl="12">
              <div
                style={{
                  display: 'flex',
                  alignContent: 'right',
                  justifyContent: 'right',
                  marginTop: '30px'
                }}
              >
                <DetailsButton onClick={() => setOpenDetails(true)}>
									Detalhes
                </DetailsButton>
              </div>
            </Col>
          </Row>
        </CardBody>
      </CardStyled>
      <Modal
        open={openDetails}
        setOpen={setOpenDetails}
        title="Detalhes do limite de crédito consignado"
        size="lg"
        footer={<ModalFooter className={hasCreditAnalysis ? 'centered': ''}>
          {
            hasCreditAnalysis && (
              <Button
                color='success'
                onClick={() => setOpenDetails(false)}
              >Entendi</Button>
            )
          }{
            !hasCreditAnalysis && (
              <Button
                color="primary"
                onClick={() => requestCreditAnalysis()}
              >Solicitar análise de crédito</Button>)
          }
        </ModalFooter>}
      >
        <ModalBody>
          <div>
            {!hasCreditAnalysis && (
              <div>
                <InfoText backgroundColor="#FFF3CD">
									Colaborador não possui nenhuma análise de crédito.
                </InfoText>
              </div>
            )}
            {hasCreditAnalysis && (
              <div>
                <TextWrapper style={{ border: 'none' }}>
                  <Text>Análise de Crédito</Text>
                  <Value>
                    {getAnalysisTypeLabel(creditAnalysisData!.type)}
                  </Value>
                </TextWrapper>
                <div style={{ paddingLeft: '14px' }}>
                  <TextWrapper>
                    <Text>Status:</Text>
                    <Value>
                      {getAnalysisStatusLabel(creditAnalysisData!.status)}
                    </Value>
                  </TextWrapper>
                  <TextWrapper>
                    <Text>Valor máximo (ACVM):</Text>
                    <Value>{formatMoney(creditAnalysisData?.limit)}</Value>
                  </TextWrapper>
                  <TextWrapper>
                    <Text>Prazo máximo (ACPM):</Text>
                    <Value>{`${creditAnalysisData?.termInMonths}x`}</Value>
                  </TextWrapper>
                  <TextWrapper style={{ border: 'none' }}>
                    <Text>Taxa (ACTX):</Text>
                    <Value>{`a partir de ${
                      ((creditAnalysisData?.interestRate ?? 0) * 100).toFixed(2)
                    }%`}</Value>
                  </TextWrapper>
                  <TextWrapper style={{ border: 'none' }}>
                    <Text>Expira em:</Text>
                    <Value>{formatUtcDate(creditAnalysisData?.expireAt)}</Value>
                  </TextWrapper>
                </div>
                <div>
                  <TextWrapper style={{ border: 'none' }}>
                    <Text>Cálculo do Limite</Text>
                  </TextWrapper>
                  <div style={{ paddingLeft: '14px' }}>
                    <TextWrapper>
                      <Text>
									Valor máximo permitido para adiantamento de salário (A):
                      </Text>
                      <Value>
                        <span style={{ color: 'green' }}>{`+ ${formatMoney(
                          paycheckMaximumAllowed
                        )}`}</span>
                      </Value>
                    </TextWrapper>
                    <TextWrapper>
                      <Text>Total de descontos em folha feitos pela Pilla (B):</Text>
                      <Value>
                        <span style={{ color: 'red' }}>{`- ${formatMoney(
                          paycheckTotalDiscount
                        )}`}</span>
                      </Value>
                    </TextWrapper>
                    <TextWrapper>
                      <Text>
									Valor líquido permitido para adiantamento de salário (C):
                      </Text>
                      <FormulaTextWrapper>
                        <Value>
                          <span style={{ color: 'green' }}>{`+ ${formatMoney(
                            calculateMaximumPaycheckAmount(
                              paycheckMaximumAllowed,
                              paycheckTotalDiscount
                            )
                          )}`}</span>
                        </Value>
                        <Formula>Fórmula: A - B</Formula>
                      </FormulaTextWrapper>
                    </TextWrapper>
                    <TextWrapper>
                      <Text>Valor máximo para consignado (D):</Text>
                      <FormulaTextWrapper>
                        <Value>
                          {formatMoney(
                            calculateConsignmentLimit(
                              paycheckMaximumAllowed,
                              paycheckTotalDiscount,
                              creditAnalysisData!.termInMonths
                            )
                          )}
                        </Value>
                        <Formula>Fórmula: C * ACPM</Formula>
                      </FormulaTextWrapper>
                    </TextWrapper>
                    <TextWrapper style={{ border: 'none' }}>
                      <Text>Valor liberado para consignado:</Text>
                      <FormulaTextWrapper>
                        <Value>{formatMoney(getConsignmentLimit())}</Value>
                        <Formula>
											Fórmula: {getConsignmentLimitFormulaLabel()}
                        </Formula>
                      </FormulaTextWrapper>
                    </TextWrapper>
                  </div>
                </div>
                <div>
                  <InfoText>
								A parcela do consignado + descontos (B) não pode ultrapassar o
								valor líquido permitido (C). Por isso, calcula-se (C * ACPM),
								pois o prazo máximo pode ser tomado desde que a primeira parcela
								esteja dentro do permitido (C).
                  </InfoText>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CardAvailableConsignmentLimit
