import { FC } from 'react'
import { ChevronUp } from 'react-feather'
import {Card, CardTitle, CardBody, Collapse as CollapseReact, CardHeader} from 'reactstrap'
import classnames from 'classnames'
import { CollapseProps } from './interfaces'

const Collapse: FC<CollapseProps> = ({ children, className, title, handleToggle, isOpen, type, icon: Icon, ...rest }) => (
  <div
    style={{display: 'flex', flex: 1, flexDirection: 'column'}}
    className={classnames('collapse-icon', {
      [className]: className,
      'collapse-default': !type,
      'collapse-shadow': type === 'shadow',
      'collapse-border': type === 'border',
      'collapse-margin': type === 'margin'
    })}
  >
    <Card
      className={classnames('app-collapse', {open: isOpen && type === 'shadow'})}>
      <CardHeader
        className={classnames('align-items-center', { collapsed: isOpen })}
        onClick={handleToggle}>
        <CardTitle style={{display: 'flex', alignItems: 'center'}}>
          {Icon && <Icon className='mr-1'/>}
          {title}
        </CardTitle>
        <ChevronUp className='collapse-icon' size={14} />
      </CardHeader>
      <CollapseReact isOpen={isOpen} {...rest}>
        <CardBody>
          {children}
        </CardBody>
      </CollapseReact>
    </Card>
  </div>
)

export default Collapse