import { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { EnvironmentConfiguration } from 'interfaces'
import { ApiProvider, ContextProvider } from 'providers'
import store from 'redux/storeConfig/store'
import { ThemeContext } from 'utils/context/themeColors'

import 'prismjs'
import 'moment/locale/pt-br'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'
import 'flatpickr/dist/themes/material_blue.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'styles/base/react/libs/toastify/toastify.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import 'assets/fonts/feather/iconfont.css'
import 'styles/core.scss'
import 'assets/scss/style.scss'
import './index.scss'
import { Spinner } from 'components'

const container = document.getElementById('root')
const root = createRoot(container!)
const envs: EnvironmentConfiguration = {
  REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL || '',
  REACT_APP_LIST_AZURE_ISSUE: process.env.REACT_APP_LIST_AZURE_ISSUE || '',
  REACT_APP_CREATE_AZURE_ISSUE: process.env.REACT_APP_CREATE_AZURE_ISSUE || ''
}

const LazyApp = lazy(() => import('containers/App'))

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
    <Provider store={store}>
      <ContextProvider>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <ApiProvider environmentConfiguration={envs}>
              <LazyApp />
              <ToastContainer newestOnTop />
            </ApiProvider>
          </ThemeContext>
        </Suspense>
      </ContextProvider>
    </Provider>
  </GoogleOAuthProvider>
)