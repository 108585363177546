import { FC } from 'react'
import { CardShadowProps } from './interface'
import { CardShreddedStyled, CardStyled } from './styles'

const CardShadow: FC<CardShadowProps> = ({ children, shredded }) => {
  if (shredded) {
    return (
      <CardShreddedStyled>
        {children}
      </CardShreddedStyled>
    )
  }
  return (
    <CardStyled>
      {children}
    </CardStyled>
  )
}

export default CardShadow