export const toQueryString = (params: any) => {
  const query = Object.keys(params).map(key => `${key}=${params[key]}`)

  // Remove param without value in query
  const newQuery: Array<string> = []
  query.forEach((q: string) => {
    if (q.charAt(q.length - 1) !== '=') {
      newQuery.push(q)
    }
  })

  return newQuery.join('&')
}

export const arraysEqual = (a1: Array<any> | undefined, a2: Array<any> | undefined) => 
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  JSON.stringify(a1) === JSON.stringify(a2)

export const objectsEqual = (obj1: any, obj2: any) => JSON.stringify(obj1) === JSON.stringify(obj2)
  
