import { Settings } from 'react-feather'

export default [
  {
    header: 'Configurações'
  },
  {
    id: 'features',
    title: 'Funcionalidades',
    icon: <Settings />,
    navLink: '/funcionalidades'
  }
]