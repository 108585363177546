/* eslint-disable react/require-default-props */
import classNames from 'classnames'
import React, { FC } from 'react'
import {
  TabContent,
  Nav,
} from 'reactstrap'

export interface PillProps {
  navItens: React.ReactElement
  tabPanes: React.ReactElement
  activeTab: string
  centered?: boolean
  className?: any
  fill?: boolean
}

const Pill: FC<PillProps> = ({navItens, tabPanes, activeTab, centered, className, fill}) => (
  <>
    <Nav 
      className={classNames({
        [className]: className,
        'justify-content-center': centered
      })} 
      fill={fill}
      pills>
      {navItens}
    </Nav>
    <TabContent className='py-50' activeTab={activeTab}>
      {tabPanes}
    </TabContent>
  </>
)
export default Pill