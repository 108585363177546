import { Row } from 'reactstrap'
import styled from 'styled-components'

export const RowItemStyled = styled(Row)`
  margin-top: 8px;
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`

export const RowBorderedStyled = styled(Row)`
  border: dashed 2px #e6e6e6;
  border-collapse: separate;
  padding: 16px;
  margin: 2px;
`