import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { User, Settings, Power } from 'react-feather'
import { handleLogout } from 'redux/actions/auth'
import Avatar from 'components/Avatar'

const UserDropdown = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userData: any = JSON.parse(localStorage.getItem('userData') || '{}')
  const userAvatar = userData.image

  const logout = () => {
    dispatch(handleLogout())
    history.replace('/login')
  }

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name font-weight-bold'>{userData.name ?? 'Usuário'}</span>
        </div>
        <Avatar img={userAvatar} imgHeight='35' imgWidth='35' status='online' />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem tag={Link} to='/pages/profile'>
          <User size={14} className='mr-75' />
          <span className='align-middle'>Profile</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to='/pages/account-settings'>
          <Settings size={14} className='mr-75' />
          <span className='align-middle'>Settings</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/login' onClick={logout}>
          <Power size={14} className='mr-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
