import { Card, CardText } from 'reactstrap'
import styled from 'styled-components'

export const CardStyled = styled(Card)`
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
`

export const AvailableLimitStyled = styled.label`
  color: #23b936;
  font-size: 1.5rem;
  font-weight: bold;
`

export const AvailableProductTitleStyled = styled(CardText)`
  color: #6e6b7b;;
  font-size: 0.875rem;
  margin-bottom: 4px;
`

export const AvailableProductLabelStyled = styled(AvailableProductTitleStyled)`
  margin-top: 8px;
`