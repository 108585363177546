import { FC, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { getAllParents, search } from 'utils/layout'
import navigation from 'navigation'
import { HorizontalNavMenuLinkProps } from './interfaces'

const HorizontalNavMenuLink: FC<HorizontalNavMenuLinkProps> = ({
  item,
  setOpenDropdown,
  setGroupActive,
  activeItem,
  setActiveItem,
  routerProps,
  currentActiveItem,
  isChild
}) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? 'a' : NavLink

  // ** URL Vars
  const location = useLocation()
  const currentURL = location.pathname

  // ** Get parents of current items
  const searchParents = (navigationSearch: any, currentURLSearch: any) => {
    const parents = search(navigationSearch, currentURLSearch, routerProps) // Search for parent object
    const allParents = getAllParents(parents, 'id') // Parents Object to Parents Array
    allParents.pop()
    return allParents
  }

  // ** Remove all items from OpenDropdown array
  const resetOpenDropdowns = () => setOpenDropdown([])

  // ** On mount update active group array
  useEffect(() => {
    if (currentActiveItem !== null) {
      setActiveItem(currentActiveItem)
      const arr = searchParents(navigation, currentURL)
      setGroupActive([...arr])
    }
  }, [location])

  return (
    <li
      className={classnames('nav-item', {
        active: item.navLink === activeItem,
        disabled: item.disabled
      })}
      onClick={resetOpenDropdowns}
    >
      <LinkTag
        className={classnames('d-flex align-items-center', {
          'dropdown-item': isChild,
          'nav-link': !isChild
        })}
        target={item.newTab ? '_blank' : '_self'}
        to={item.navLink || '/'}
        isActive={(match) => {
          if (!match) {
            return false
          }

          if (match.url && match.url !== '' && match.url === item.navLink) {
            currentActiveItem = item.navLink
          }
          return true
        }}
      >
        {item.icon}
        <span>
          <FormattedMessage id={item.title} />
        </span>
      </LinkTag>
    </li>
  )
}

export default HorizontalNavMenuLink
