/* eslint-disable import/no-cycle */
import { FC, useEffect, useState } from 'react'
import { Badge, CardBody, CardText, Row, } from 'reactstrap'
import { formatMoney, getBadgeProductStatus, getProductName } from 'utils/layout'
import { CardComposedLimitProps } from './interfaces'
import {
  AvailableLimitStyled,
  CardStyled,
  AvailableProductTitleStyled,
  AvailableProductLabelStyled,
} from './styles'

const CardComposedLimit: FC<CardComposedLimitProps> = ({
  availableLimitValue,
  title,
  status,
  products,
  viablePaymentLabel
}) => {

  const [isProductActive, setIsProductActive] = useState<boolean>(false)

  useEffect(() => {
    setIsProductActive(status === 'ativo')
  }, [])

  return (
    <CardStyled>
      <CardBody>
        <CardText>{title}</CardText>
        <p>{getBadgeProductStatus(status, false)}</p>
        <AvailableLimitStyled style={{ opacity: !isProductActive ? 0.5 : 1 }}>{formatMoney(availableLimitValue)}</AvailableLimitStyled>
        <AvailableProductTitleStyled>
          Produtos disponíveis
        </AvailableProductTitleStyled>
        <Row style={{ margin: 0 }}>
          {products.map((product: string) =>
            (<Badge color='light-primary' style={{ marginRight: 2, marginBottom: 2 }}>{getProductName(product)}</Badge>)
          )}
        </Row>
        <AvailableProductLabelStyled>
          {viablePaymentLabel}
        </AvailableProductLabelStyled>
      </CardBody>
    </CardStyled>
  )
}

export default CardComposedLimit