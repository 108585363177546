import { FC } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import GoogleCalendarIcon from 'assets/images/svg/google-calendar.svg'
import { AllowCalendarGoogleProps } from './interfaces'

const AllowCalendarGoogleButton: FC<AllowCalendarGoogleProps> = ({ onResponse }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async (response: any) => {
      onResponse(response)
    },
    onError: (errorResponse: any) => {
      console.error(errorResponse)
      toast.error('Erro ao autorizar Google Calendar para o usuário.')
    },
    flow: 'auth-code',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI_GOOGLE_CALENDAR,
    scope: 'https://www.googleapis.com/auth/calendar'
  })

  return (
    <Button
      outline
      color='primary'
      icon={() => <img src={GoogleCalendarIcon} alt='Logo Google' width={32}/>}
      text='Permitir acesso ao meu calendário'
      className='centered'
      onClick={googleLogin} 
      style={{display:'flex', flex: 1}} />
  )
}

export default AllowCalendarGoogleButton