import { FC } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { InputProps } from './interfaces'

const Input: FC<InputProps> = ({
  register,
  name,
  error,
  label,
  wrapperClass,
  textarea = false,
  ...rest
}) => (
  <Row>
    <Col xl='12'>
      {label && <Label htmlFor={name}>{label}</Label>}
      {!textarea &&
        <input
          style={{display: 'flex', flex: '1'}}
          className='form-control'
          aria-invalid={error ? 'true' : 'false'}
          {...register(name)}
          {...rest}
        />}
      {textarea &&
        <textarea
          style={{display: 'flex', flex: '1', height: 300}}
          className='form-control'
          aria-invalid={ error? 'true' : 'false'}
          {...register(name)}
          {...rest}
        />
      }
      {error && <span role='alert' className='text-danger'>{error}</span>}
    </Col>
  </Row>
)


export default Input