import Spinner from 'components/Spinner'
import { FC } from 'react'
import { ThumbsDown, ThumbsUp } from 'react-feather'
import Switch from 'react-switch'
import { SwitchProps } from './interfaces'
import { LabelLeft, LabelRight, SwitchContainer } from './styles'

const SwitchComponent: FC<SwitchProps> = (props) => {
  const { rightLabel, leftLabel, id, loading, useIcon, disabled, ...rest } = props

  return (
    <SwitchContainer id={id}>
      {leftLabel && <LabelLeft>{leftLabel}</LabelLeft>}
      <Switch
        uncheckedIcon={useIcon ? <ThumbsDown size={20} color="#fff" style={{ marginLeft: 5, marginTop: 5 }} /> : false}
        checkedIcon={useIcon ? <ThumbsUp size={20} color="#fff" style={{ marginLeft: 8, marginTop: 3 }} /> : false}
        onColor='#2659D4'
        disabled={loading || disabled}
        handleDiameter={20}
        {...rest}
      />
      {loading && <Spinner size='sm' style={{ top: '10px', right: '5px' }} />}
      {rightLabel && <LabelRight>{rightLabel}</LabelRight>}
    </SwitchContainer>
  )
}

export default SwitchComponent